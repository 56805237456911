import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthService } from 'shared-lib'

@Injectable({
  providedIn: 'root'
})
export class AccessGuard {
  constructor (
    public auth: AuthService,
    public router: Router
  ) { }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const loggedIn = this.auth.isAuthenticated()

    const invite_token = route.queryParams.invite_token
    const invite_type = route.queryParams.invitation_type

    if (!loggedIn) {
      this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: state.url }, queryParamsHandling: 'merge' })
      return false
    }

    if (invite_token && (invite_type === 'org')) {
      this.router.navigate(['/auth/join'], { queryParams: { invite_token, redirectUrl: state.url } })
      return false
    }

    return true
  }
}
